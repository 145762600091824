import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {ApiProvider} from '../api/api';
import {Observable} from 'rxjs';
import {User} from './user';

import {environment} from '../../../environments/environment';
import {UserResponse} from './user-response';
import {Model} from '../abstract/model';

@Injectable()
export class UserProvider extends ApiProvider<User, UserResponse> {
  protected resourceName = 'user';

  /**
   * Get currently authenticated user
   * @returns {Observable<User>}
   */
  public getCurrentUser(): Observable<User> {

    return this.http.get<SingleResponse<UserResponse>>(
      `${environment.API.ENDPOINT}/user/auth?include=acl_roles`
    ).pipe(
      map((response) => {
        return User.createFromResponse(response.data);
      }));
  }

  createFromResponse(response: SingleResponse<UserResponse>): User {
    return User.createFromResponse(response.data);
  }

  collectionFromResponse(response: CollectionResponse<UserResponse>): Array<User> {
    return User.collectionFromResponse(response.data);
  }
}
