import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {ChangePasswordDialog} from './change-password.dialog';
import {User} from '../../core/user/user';
import {AuthService} from '../../core/auth/auth.service';
import {environment} from '../../../environments/environment';
import {Breadcrumb} from './breadcrumb';
import {MENU_ITEMS} from './menu-items';

@Component({
  selector: 'panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {

  static allMenuSections = MENU_ITEMS;

  menuSections: any = [];

  public user: User;
  @Input() public breadcrumbs: Array<Breadcrumb>;
  @Input() public toolbar = false;
  @Input() public panelTitle?: string;
  @Input() showToolbar = true;

  public version = environment.version;

  constructor(
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.authService.observeUser().subscribe((user) => {
      this.user = user;
      this.parseMenuItems();
    });
  }

  /**
   * Logout and redirect to login.
   */
  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['login']);
    });
  }

  openChangePasswordModal() {
    this.dialog.open(ChangePasswordDialog, {
      width: '350px',
      data: this.user,
    });
  }

  parseMenuItems() {
    let sections = PanelComponent.allMenuSections.slice();

    sections.forEach((section) => {
      section.items = section.items.filter((item) => {
        let hasAllRoles = true;

        if (item.roles) {
          item.roles.forEach((role) => {
            if (!this.user.hasRole(role)) {
              hasAllRoles = false;
            }
          });
        }

        return hasAllRoles;
      });
    });

    sections = sections.filter((section) => !!section.items.length);

    this.menuSections = sections;
  }
}
