
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {AuthService} from '../core/auth/auth.service';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {

  }
  /**
   * Only authenticated users can activate
   * routes under this guard.
   * @returns {Observable<boolean>}
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.observeUser().pipe(map((user) => {
      if (!user) {
        this.router.navigate(['/login']);
      }

      if (route.data.roles) {
        let hasAllRoles = true;

        route.data.roles.forEach((role) => {
          if (!user.hasRole(role)) {
            hasAllRoles = false;
          }
        });

        if (!hasAllRoles) {
          this.router.navigate(['/']);
          return false;
        }
      }

      return !!user;
    }));
  }
}
