import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {ClientRequest} from './client-request';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../../../environments/environment';
import {ClientRequestTimelineItem} from './client-request-timeline-item';
import {EmailMessage} from '../email-message/email-message';
import {ClientRequestStatus} from './client-request-status';
import {ClientRequestTimelineItemResponse} from './client-request-timeline-item.response';
import {ClientRequestResponse} from './client-request.response';

@Injectable()
export class ClientRequestService extends ApiProvider<ClientRequest, ClientRequestResponse> {
  protected resourceName = 'client_request';

  collectionFromResponse(response: CollectionResponse<ClientRequestResponse>): Array<ClientRequest> {
    return ClientRequest.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<ClientRequestResponse>): ClientRequest {
    return ClientRequest.createFromResponse(response.data);
  }

  /**
   * Create a timeline item with given text for given ClientRequest `id`
   * @param {string | number} id
   * @param {string} text
   * @returns {Observable<ClientRequestTimelineItem>}
   */
  createTimelineitem(id: string|number, text: string): Observable<ClientRequestTimelineItem> {
    const url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}/${id}/timeline_item`);

    return this.http.post<SingleResponse<ClientRequestTimelineItemResponse>>(url.toString(), {
      text
    }).pipe(map((response) => {
        return ClientRequestTimelineItem.createFromResponse(response.data);
      }));
  }

  /**
   * Generate Travel Design Fee Receivable for given ClientRequest `id` and Entity `id`
   * @param {string | number} id
   * @param {string | number} entity_id
   * @returns {Observable<ClientRequestTimelineItem>}
   */
  generateTravelDesignFee(id: string|number, entity_id: string|number): Observable<any> {
    const url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}/${id}/generate/travel_design_fee`);

    return this.http.post<SingleResponse<ClientRequestTimelineItemResponse>>(url.toString(), {
      entity_id
    });
  }

  /**
   * Watch given ClientRequest id
   * @param {string | number} id
   * @returns {Observable<boolean>} Always true
   */
  watch(id: string|number): Observable<boolean> {
    const url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}/${id}/watch`);

    return this.http.post<void>(url.toString(), {}).pipe(map(() => true));
  }

  /**
   * Unwatch given ClientRequest id
   * @param {string | number} id
   * @returns {Observable<boolean>} Always false
   */
  unwatch(id: string|number): Observable<boolean> {
    const url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}/${id}/unwatch`);

    return this.http.post<void>(url.toString(), {}).pipe(map(() => false));
  }

  /**
   *
   * @param {string | number} id
   * @param {EmailMessage} emailMessage
   * @returns {Observable<EmailMessage>}
   */
  sendEmail(id: string|number, emailMessage: EmailMessage): Observable<void> {
    const url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}/${id}/email/send`);

    return this.http.post<void>(url.toString(), {
      subject: emailMessage.subject,
      body: emailMessage.body,
      to: emailMessage.to,
      cc: emailMessage.cc,
      attachments: emailMessage.attachments,
      copy_attachments_from_email_message_id: emailMessage.in_reply_to_id,
    });
  }

  changeStatus(id: string|number, status: number, deadline?: Date): Observable<ClientRequestStatus> {
    const url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}/${id}/status`);

    return this.http.patch<SingleResponse<ClientRequestStatusResponse>>(url.toString(), {
      status,
      deadline
    }).pipe(map((response) => {
      return ClientRequestStatus.createFromResponse(response.data);
    }));
  }
}
