import {Model} from '../../abstract/model';
import {AclRole} from './acl-role';
import {AclTemplateResponse} from './acl-template.response';

export class AclTemplate extends Model {
  label: string;
  description: string;
  roles: Array<AclRole> = [];

  /**
   * @param response
   * @returns {Model}
   */
  public static createFromResponse(response: AclTemplateResponse): AclTemplate {
    const model =  new this(response);

    model.roles = response.roles ? AclRole.collectionFromResponse(response.roles) : null;

    return model;
  }
}
