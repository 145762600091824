import {Model} from '../../abstract/model';
import {AclTemplateResponse} from './acl-template.response';
import {AclRoleResponse} from './acl-role.response';
import {AclRole} from './acl-role';
import {AclRoleGroupResponse} from './acl-role-group.response';

export class AclRoleGroup extends Model {
  code: string;
  label: string;
  roles: Array<AclRole>;

  /**
   * @param response
   * @returns {Model}
   */
  public static createFromResponse(response: AclRoleGroupResponse): AclRoleGroup {
    const model = new this(response);

    model.roles = AclRole.collectionFromResponse(response.roles);

    return model;
  }
}
