import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {File as FileModel} from './file';
import {TdFileService} from '@covalent/core';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class FileService extends ApiProvider<FileModel, FileResponse> {
  protected resourceName = 'file';

  collectionFromResponse(response: CollectionResponse<FileResponse>): Array<FileModel> {
    return FileModel.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<FileResponse>): FileModel {
    return FileModel.createFromResponse(response.data);
  }

  /**
   * @param {File} file
   *
   * @returns {Observable<HttpEvent<SingleResponse<FileResponse>>>}
   */
  public upload(file: File): Observable<HttpEvent<SingleResponse<FileResponse>>> {
    const url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}`).toString();

    const formData: FormData = new FormData();
    formData.append('file', file);

    const request = new HttpRequest<FormData>('POST', url, formData, {
      reportProgress: true
    });

    return this.http.request<SingleResponse<FileResponse>>(request);
  }
}
