
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../core/auth/auth.service';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {

  }

  /**
   * Only guest can activate routes under this guard
   * @returns {Observable<boolean>}
   */
  canActivate(): Observable<boolean> {
    return this.authService.observeUser().pipe(map((user) => {
      if (user) {
        this.router.navigate(['']);
      }

      return !user;
    }));
  }
}
