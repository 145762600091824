<div *tdLoading="'password'; strategy:'overlay';">
    <h1 mat-dialog-title>Change password</h1>
    <div mat-dialog-content>
        <p>Please enter your new password.</p>
        <form [formGroup]="form">
            <mat-form-field>
                <input matInput type="password" placeholder="Password" tabindex="1" formControlName="password">
            </mat-form-field>
            <mat-form-field>
                <input matInput type="password" placeholder="Confirm password" tabindex="1" formControlName="passwordConfirmation">
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button (click)="cancel()" tabindex="-1">Cancel</button>
        <button mat-button color="primary" (click)="save()" [disabled]="!form.valid" tabindex="2">Save</button>
    </div>
</div>