import {Model} from '../../abstract/model';
import {SharedPaymentShare} from '../shared-payment-share/shared-payment-share';
import {SharedPaymentResponse} from './shared-payment.response';

export class SharedPayment extends Model {
  id: number;
  payable_type: string;
  payable_id: number;
  title: string;
  description: string;
  maximum_shares: number;
  maximum_installments: number;
  minimum_share: number;
  maximum_share: number;
  maximum_total: number;

  last_installment_before: Date;
  created_at: Date;
  updated_at: Date;

  shares: Array<SharedPaymentShare> = [];
  shares_count: number;

  /**
   * The model type on API
   * @type {string}
   */
  model_type = 'Cdtsys\\GenericCampaign\\Models\\GenericCampaign';

  /**
   * @param {SharedPaymentResponse} response
   * @returns {User}
   */
  public static createFromResponse(response: SharedPaymentResponse): SharedPayment {
    const sharedPayment = new SharedPayment(response);

    if (sharedPayment.last_installment_before) {
      sharedPayment.last_installment_before = new Date(response.last_installment_before);
    }

    sharedPayment.created_at = new Date(response.created_at);
    sharedPayment.updated_at = new Date(response.updated_at);

    if (response.shares) {
      sharedPayment.shares = SharedPaymentShare.collectionFromResponse(response.shares);
    }

    return sharedPayment;
  }

  /**
   * @param {SharedPaymentShare} share
   */
  public updateOrCreateShare(share: SharedPaymentShare) {
    let updated = false;

    this.shares = this.shares.map((item) => {
      if (item.id === share.id) {
        updated = true;
        return share;
      } else {
        return item;
      }
    });

    if (!updated) {
      this.shares.push(share);
    }

    this.shares = this.shares.slice();
  }
}
