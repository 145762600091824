export const MENU_ITEMS = [
  {
    title: 'CRM',
    items: [
      { title: 'Client Request', icon: 'assignment', link: '/client_request', roles: ['client_request.view'] }
    ]
  },
  {
    title: 'Travel Guide',
    items: [
      { title: 'Travel Resources', icon: 'landscape', link: '/travel_resource', roles: ['travel_resource.view'] }
    ]
  },
  {
    title: 'Financial',
    items: [
      { title: 'Shared Payments', icon: 'share', link: '/shared_payment', roles: ['shared_payment.view'] },
    ]
  },
  {
    title: 'Marketing',
    items: [
      { title: 'Generic Campaigns', icon: 'rss_feed', link: '/generic_campaign', roles: ['generic_campaign.view'] },
    ]
  },
  {
    title: 'Reports',
    items: [
      { title: 'Kibana Reports', icon: 'insert_chart_outlined', link: '/kibana_report', roles: ['kibana.view'] },
    ]
  },
  {
    title: 'System',
    items: [
      { title: 'Users', icon: 'people', link: '/user', roles: ['user.view'] },
      { title: 'Acl Templates', icon: 'security', link: '/acl/template', roles: ['acl.template.view'] },
      // { title: 'Settings', icon: 'settings', link: '/setting', roles: [] }
    ]
  },
]
