
import {empty as observableEmpty} from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {EmailMessage} from './email-message';
import {EmailMessageAttachment} from './email-message-attachment';
import {File} from '../file/file';
import 'rxjs-compat/add/observable/empty';
import {EmailMessageResponse} from './email-message.response';

@Injectable()
export class EmailMessageService extends ApiProvider<EmailMessage, EmailMessageResponse> {
  protected resourceName = 'email';

  collectionFromResponse(response: CollectionResponse<EmailMessageResponse>): Array<EmailMessage> {
    return EmailMessage.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<EmailMessageResponse>): EmailMessage {
    return EmailMessage.createFromResponse(response.data);
  }

  /**
   *
   * @returns {Observable<HttpEvent<SingleResponse<EmailMessageResponse>>>}
   * @param emailMessage
   */
  public read(emailMessage: EmailMessage): Observable<EmailMessage> {
    if (emailMessage.read_at) {
      return observableEmpty();
    }

    const url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}/${emailMessage.id}/read`).toString();

    return this.http.post<SingleResponse<EmailMessageResponse>>(url.toString(), {}).pipe(map((response) => {
      return this.createFromResponse(response);
    }));
  }

  /**
   *
   * @returns {Observable<HttpEvent<SingleResponse<EmailMessageResponse>>>}
   * @param attachment
   */
  public copyAttachment(attachment: EmailMessageAttachment): Observable<File> {
    const url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}/attachment/${attachment.id}/copy`).toString();

    return this.http.post<SingleResponse<FileResponse>>(url.toString(), {}).pipe(map((response) => {
      return File.createFromResponse(response.data);
    }));
  }
}
