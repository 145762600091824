export class Model {
  public id?: number|string;

  get primary_key() {
    return this.id;
  }

  /**
   * Construct a model from attributes
   * @param attributes
   */
  constructor(attributes?: any) {
    if (attributes) {
      Object.assign(this, attributes);
    }
  }

  /**
   * Create a collection of models from array with attributes
   * @param {Array<any>} items
   * @returns {Array}
   */
  public static collectionFromResponse(items: Array<any>) {
    const collection = [];

    items.forEach((attributes) => {
      collection.push(this.createFromResponse(attributes));
    });

    return collection;
  }

  /**
   *
   * @param response
   * @returns {any}
   */
  public static createFromResponse(response: any): any {
    return new this(response);
  }

  /**
   * Compare two models
   * @param a
   * @param b
   * @returns {boolean}
   */
  public static compare(a: Model, b: Model): boolean {
    return a && b && a.primary_key === b.primary_key;
  }

  /**
   * Check either model exists or not on database
   * @returns {boolean}
   */
  public exists(): boolean {
    return !!this.primary_key;
  }
}
