
import {map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiProvider} from '../api/api';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {User} from '../user/user';
import {UserProvider} from '../user/user.provider';







import {environment} from '../../../environments/environment';
import {StorageProvider} from '../storage/storage.provider';
import {AuthTokenProvider} from './auth-token-provider';


@Injectable()
export class AuthService extends ApiProvider<any, any> {
  private userSubject: ReplaySubject<User> = new ReplaySubject(1);
  private fetchingUser = false;

  readonly TOKEN_STORAGE_KEY = 'auth.token';

  constructor(
    public http: HttpClient,
    public storage: StorageProvider,
    public userProvider: UserProvider,
    public tokenProvider: AuthTokenProvider,
  ) {
    super(http);

    this.tokenProvider.observeToken().subscribe((token) => {
      if (token) {
        this.fetchUser();
      } else {
        this.userSubject.next(null);
      }
    });
  }

  /**
   * @returns {Observable<User>}
   */
  public observeUser(): Observable<User> {
    return this.userSubject.asObservable();
  }

  /**
   * Fetch user from API if token is available
   */
  private fetchUser() {
    if (!this.fetchingUser) {
      return this.userProvider.getCurrentUser().subscribe((user: User) => {
        this.userSubject.next(user);
        this.fetchingUser = false;
      }, (error) => {
        if (error.status === 401) {
          this.tokenProvider.removeToken();
          this.userSubject.next(null);
        } else {
          throw error;
        }
      });
    }
  }

  /**
   * Attempt to login using email and password credentials
   *
   * @param email
   * @param password
   * @returns {Promise<Boolean>}
   */
  public login(email, password): Observable<Boolean> {
    return this.http.post<AuthToken>(`${environment.API.ENDPOINT}/oauth/token`, {
      grant_type: 'password',
      client_id: environment.API.CLIENT_ID,
      client_secret: environment.API.CLIENT_SECRET,
      username: email,
      password: password,
      scope: '',
    }).pipe(map((token: AuthToken) => {
      this.userSubject = new ReplaySubject(1);
      this.tokenProvider.setToken(token).subscribe(() => {
        this.fetchUser();
      });

      return true;
    }));
  }

  /**
   * Login using Facebook
   * @returns {Promise<boolean>}
   */
  public loginWithFacebook() {
    // @todo: Implement it!
    return false;
  }

  /**
   * Logout
   * @returns {Promise<any>}
   */
  logout(): Observable<any> {
    return this.tokenProvider.removeToken().pipe(map(() => {
      this.userSubject.next(null);
    }));
  }

  getModel(): User {
    return new User();
  }
}
