import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import {CovalentLayoutModule, CovalentLoadingModule, CovalentMessageModule} from '@covalent/core';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { RoutingModule } from './routing/routing.module';
import {CoreModule} from './core/core.module';
import {LoginComponent} from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {UiModule} from './ui/ui.module';
import {environment} from '../environments/environment';
import {A11yModule} from '@angular/cdk/a11y';

@NgModule({
  entryComponents: [],
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    RoutingModule,

    UiModule,

    /**
     * Teradata Covalent
     */

    CovalentLayoutModule,
    CovalentLoadingModule,
    CovalentMessageModule,

    /**
     * Angular Material modules (see https://material.angular.io)
     */
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatSnackBarModule,
    A11yModule,
    MatSliderModule,

    /**
     * Google Maps
     */

    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAPS_API_KEY
    }),

    /**
     * Application Modules
     */

    CoreModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
