import {Model} from '../../abstract/model';

export class LocationCity extends Model {
  ufi: number;
  full_name: string;
  country_name: string;
  adm1_code: string;
  adm1_full_name: string;
  adm2_full_name: string;
  adm2_code: string;
  formatted_name: string;

  /**
   * @param items
   * @returns {Model}
   */
  public static createFromResponse(items: any): LocationCity {
    return new this(items);
  }

  toString(): string {
    return `${this.full_name} - ${this.adm1_full_name} - ${this.country_name}`;
  }
}
