import {Model} from '../../abstract/model';

export class LocationCountry extends Model {
  cc_iso: string;
  country_name: string;
  tld: string;

  /**
   * @param items
   * @returns {Model}
   */
  public static createFromResponse(items: any): LocationCountry {
    return new this(items);
  }

  toString(): string {
    return this.country_name;
  }
}
