import { Component, OnInit } from '@angular/core';
import {TdLoadingService} from '@covalent/core';
import {AuthService} from '../core/auth/auth.service';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  public error;

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: TdLoadingService,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  /**
   * Check if user is already authenticated, and
   * redirect it to default route if so.
   */
  ngOnInit() {
    this.form = this.buildForm();
  }

  /**
   * Attempt to login on AuthService, redirecting user if success,
   * or showing an error message if failed.
   */
  public login(): void {
    this.loadingService.register('login');
    this.error = null;

    this.authService.login(this.form.get('email').value, this.form.get('password').value).subscribe(() => {
      this.loadingService.resolve('login');
      this.navigateToDefault();
    }, (error) => {
      this.error = error.error.error;
      this.loadingService.resolve('login');
      this.form.get('password').reset();
    });
  }

  /**
   * Build Form
   * @returns {FormGroup}
   */
  private buildForm(): FormGroup {
    const formConfig = {
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    };

    return this.formBuilder.group(formConfig);
  }

  /**
   * Navigate to default route
   */
  private navigateToDefault(): void {
    this.router.navigate(['']);
  }
}
