import {Model} from '../../abstract/model';

export class Setting extends Model {
  key: string;
  value?: any;
  required: boolean;
  type: string;
  unit?: string;
  min?: number;
  title?: string;
  placeholder?: string;
  validators: Array<any>;
}
