import {Injectable, NgZone} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as Pusher from 'pusher-js';
import {AuthTokenProvider} from '../auth/auth-token-provider';
import {ReplaySubject, Observable} from 'rxjs';

@Injectable()
export class BroadcastService {
  private client: Pusher;
  private clientSubject: ReplaySubject<Pusher> = new ReplaySubject(1);

  constructor(
    private authTokenProvider: AuthTokenProvider,
    private _zone: NgZone
  ) {
    this.buildClient();
  }

  private buildClient() {
    this.authTokenProvider.observeToken().subscribe((token: AuthToken) => {
      if (token) {
        this.clientSubject.next(new Pusher(environment.PUSHER_APP_KEY, {
          cluster: environment.PUSHER_CLUSTER,
          encrypted: true,
          authEndpoint: `${environment.API.ENDPOINT}/broadcasting/auth`,
          auth: {
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token.access_token
            }
          },
        }));
      }
    });
  }

  /**
   * Subscribe to given channel and event
   * @param {string} channel
   * @param {string} event
   * @returns {Observable<T>}
   */
  public subscribe<T>(channel: string, event: string): Observable<T> {
    return new Observable((subject) => {
      this.clientSubject.subscribe((client) => {
        client.subscribe(channel).bind(event, (data) => {
          this._zone.run(() => {subject.next(data); });
        });
      });
    });
  }
}
