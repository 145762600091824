import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {TravelResource} from './travel-resource';

@Injectable()
export class TravelResourceService extends ApiProvider<TravelResource, TravelResourceResponse> {
  protected resourceName = 'travel_resource';

  collectionFromResponse(response: CollectionResponse<TravelResourceResponse>): Array<TravelResource> {
    return TravelResource.collectionFromResponse(response.data);
  }
}
