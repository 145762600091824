import {Model} from '../../abstract/model';

export class File extends Model {
  id: number;
  label: string;
  filename: string;
  extension: string;
  view_link: string;
  download_link: string;
  thumbnail_link: string;
  size: number;
  default: boolean;

  get filenameWithExtension() {
    return `${this.filename}.${this.extension}`;
  }
}
