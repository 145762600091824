import { Component, OnInit } from '@angular/core';
import {TdLoadingService} from '@covalent/core';
import {AuthService} from '../core/auth/auth.service';
import {User} from '../core/user/user';
import { Router }                 from '@angular/router';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public email: string;
  public password: string;

  constructor(
    private loadingService: TdLoadingService,
    private AuthService: AuthService,
    private router: Router,
  ) {
  }

  /**
   * Check if user is already authenticated, and
   * redirect it to default route if so.
   */
  ngOnInit() {
    this.AuthService.observeUser().subscribe((user: User) => {
      if (user) {
        this.navigateToDefault();
      }
    });
  }

  /**
   * Attempt to login on AuthService, redirecting user if success,
   * or showing an error message if failed.
   */
  public login() {
    this.loadingService.register('login');

    this.AuthService.login(this.email, this.password).subscribe(() => {
      this.loadingService.resolve('login');
      this.navigateToDefault();
    });
  }

  /**
   * Navigate to default route
   */
  private navigateToDefault() {
    this.router.navigate(['']);
  }
}
