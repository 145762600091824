import {Model} from '../../abstract/model';
import {MailchimpCampaignResponse} from './mailchimp-campaign.response';

export class MailchimpCampaign extends Model {
  /**
   * The model type on API
   * @type {string}
   */
  static model_type = 'App\\Models\\Mailchimp\\Campaign';

  id: number;
  body: any;

  /**
   * @returns {Model}
   * @param response
   */
  public static createFromResponse(response: MailchimpCampaignResponse): MailchimpCampaign {
    return new this(response);
  }
}
