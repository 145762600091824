<mat-card *tdLoading="'login'; strategy:'overlay';">
  <mat-card-content>
    <img class="logo" src="../assets/img/logo-black.svg" alt="CDTSYS">
    <td-message sublabel="Invalid OAuth2 client. Please contact our support team." color="warn" icon="error" [opened]="error == 'invalid_client'"></td-message>
    <td-message sublabel="Wrong password or email." color="warn" icon="error" [opened]="error == 'invalid_credentials'"></td-message>
    <form [formGroup]="form">
      <mat-form-field>
        <input matInput autocomplete="email" placeholder="E-mail" formControlName="email">
      </mat-form-field>

      <mat-form-field>
        <input matInput autocomplete="current-password" type="password" placeholder="Password" formControlName="password">
      </mat-form-field>

      <button mat-raised-button color="accent" [disabled]="!form.valid" (click)="login(); $event.preventDefault();">
        Login
      </button>
    </form>
  </mat-card-content>
</mat-card>
