import {Model} from '../../abstract/model';
import {GenericCampaignResponse} from './generic-campaign.response';

export class GenericCampaign extends Model {

  /**
   * The model type on API
   * @type {string}
   */
  static model_type = 'Cdtsys\\GenericCampaign\\Models\\GenericCampaign';

  id: number;
  unique_id: string;
  label: string;
  description: number;
  created_at: Date;
  updated_at: Date;

  /**
   * @param {GenericCampaignResponse} response
   * @returns {User}
   */
  public static createFromResponse(response: GenericCampaignResponse): GenericCampaign {
    const genericCampaign = new GenericCampaign(response);

    genericCampaign.created_at = new Date(response.created_at);
    genericCampaign.updated_at = new Date(response.updated_at);

    return genericCampaign;
  }
}
