
export const environment = {
  version: "1.9.1",
  production: true,
  OLD_URL: "https://cdtsys.iget.com.br",
  API: {
    ENDPOINT: "https://cdtsys.iget.com.br/api",
    CLIENT_ID: 3,
    CLIENT_SECRET: 'anRB9XEIGWjMkm4Et2YP2ymYES1x8fJKvqiAbOGJ',
  },
  GOOGLE_MAPS_API_KEY: 'AIzaSyBrCxsxUS-IxNbURtJak3Q8NqLduVg42J4',
  PUSHER_APP_KEY: '5f4c82c13a6e9f267d30',
  PUSHER_CLUSTER: 'mt1',
  MAX_UPLOAD_SIZE: 314572800
};
