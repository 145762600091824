
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {Setting} from './setting';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SettingService {
  protected resourceName = 'setting';

  constructor(
    private http: HttpClient,
  ) {

  }

  collectionFromResponse(response: CollectionResponse<SettingResponse>): Array<Setting> {
    return Setting.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<SettingResponse>): Setting {
    return Setting.createFromResponse(response.data);
  }

  /**
   * Load given setting key under given namespace
   * @param {string} namespace
   * @param {string} key
   * @returns {Observable<Setting>}
   */
  public load(namespace: string, key: string): Observable<Setting> {
    const url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}/${namespace}/${key}`);

    return this.http.get<SingleResponse<SettingResponse>>(url.toString()).pipe(
      map((response) => {
        return this.createFromResponse(response);
      }));
  }

  /**
   * List all setting under given namespace
   *
   * @param {string} namespace
   * @returns {Observable<Collection<Setting>>}
   */
  public list(namespace?: string): Observable<Collection<Setting>> {
    let url: URL;

    if (namespace) {
      url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}/${namespace}`);
    } else {
      url = new URL(`${environment.API.ENDPOINT}/${this.resourceName}`);
    }

    return this.http.get<CollectionResponse<SettingResponse>>(url.toString()).pipe(
      map((response) => {
        return {
          data: this.collectionFromResponse(response),
          meta: response.meta
        };
      }));
  }
}
