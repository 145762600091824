import {Model} from '../../abstract/model';
import {PermissionGroupResponse} from './permission-group.response';

export class PermissionGroup extends Model {
  id: number;
  name: string;

  /**
   * @param response
   * @returns {Model}
   */
  public static createFromResponse(response: PermissionGroupResponse): PermissionGroup {
    return new this(response);
  }
}
