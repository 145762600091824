import {Model} from '../../abstract/model';
import {GoogleClick} from './google-click';
import {MailchimpCampaign} from './mailchimp-campaign';
import {GenericCampaign} from '../generic-campaign/generic-campaign';

export class Conversion extends Model {
  id: number;
  type: string;
  conversion_type: string;
  conversion: GoogleClick|MailchimpCampaign|GenericCampaign;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;

  /**
   * @returns {Model}
   * @param response
   */
  public static createFromResponse(response: ConversionResponse): Conversion {
    const conversion = new this(response);

    switch (response.conversion_type) {
      case GoogleClick.model_type:
        conversion.conversion = GoogleClick.createFromResponse(response.conversion);
        conversion.type = 'adwords';
        break;
      case MailchimpCampaign.model_type:
        conversion.conversion = MailchimpCampaign.createFromResponse(response.conversion);
        conversion.type = 'mailchimp';
        break;
      case GenericCampaign.model_type:
        conversion.conversion = GenericCampaign.createFromResponse(response.conversion);
        conversion.type = 'generic';
        break;
    }

    conversion.created_at = new Date(response.created_at);
    conversion.updated_at = new Date(response.updated_at);
    conversion.deleted_at = new Date(response.deleted_at);

    return conversion;
  }
}
