import 'rxjs-compat/add/operator/first';
import 'rxjs-compat/add/operator/mergeMap';
import {AuthTokenProvider} from './auth-token-provider';
import {first, flatMap} from 'rxjs/operators';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authTokenProvider;

  constructor(
    private injector: Injector
  ) {
    this.authTokenProvider = this.injector.get(AuthTokenProvider);
  }

  /**
   * Intercept requests adding the AuthToken if available
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authTokenProvider.observeToken().pipe(
      first(),
      flatMap((token: AuthToken) => {
        if (token) {
          request = request.clone({setHeaders: {
              authorization: `${token.token_type} ${token.access_token}`
            }});
        }

        return next.handle(request);
      })
    );
  }
}
