import {Model} from '../../abstract/model';
import {AclTemplateResponse} from './acl-template.response';
import {AclRoleResponse} from './acl-role.response';

export class AclRole extends Model {
  code: string;
  label: string;
  description: string;

  /**
   * @param response
   * @returns {Model}
   */
  public static createFromResponse(response: AclRoleResponse): AclRole {
    return new this(response);
  }
}
