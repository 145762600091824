import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PanelComponent} from './panel/panel.component';
import {ChangePasswordDialog} from './panel/change-password.dialog';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  CovalentCommonModule,
  CovalentLayoutModule,
  CovalentLoadingModule,
  CovalentMessageModule,
  CovalentSearchModule
} from '@covalent/core';
import { ModelListComponent } from './model-list/model-list.component';
import {FillComponent} from './panel/fill.component';
import {HasRoleDirective} from './has-role.directive';
import {HasNotRoleDirective} from './has-not-role.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,

    /**
     * Teradata Covalent
     */

    CovalentCommonModule,
    CovalentLayoutModule,
    CovalentLoadingModule,
    CovalentMessageModule,
    CovalentSearchModule,

    /**
     * Angular Material modules (see https://material.angular.io)
     */
    MatBadgeModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    PanelComponent,
    FillComponent,
    ModelListComponent,
    ChangePasswordDialog,
    HasRoleDirective,
    HasNotRoleDirective
  ],
  entryComponents: [
    ChangePasswordDialog,
  ],
  exports: [
    PanelComponent,
    FillComponent,
    ModelListComponent,
    HasRoleDirective,
    HasNotRoleDirective
  ]
})
export class UiModule { }
