import {enableProdMode, LOCALE_ID} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localePt, 'pt');

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
})
  .catch(err => console.log(err));
