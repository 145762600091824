import {Model} from '../../abstract/model';
import {User} from '../../user/user';
import {EmailMessage} from '../email-message/email-message';
import {ClientRequestTimelineItemResponse} from './client-request-timeline-item.response';

export class ClientRequestTimelineItem extends Model {
  id: number;
  text: string;
  author_user: User;
  email_message: EmailMessage;

  created_at: Date;
  created_at_date: Date;
  updated_at: Date;

  /**
   * @param {ClientRequestTimelineItemResponse} response
   * @returns {User}
   */
  public static createFromResponse(response: ClientRequestTimelineItemResponse): ClientRequestTimelineItem {
    const clientRequestTimelineItem = new ClientRequestTimelineItem(response);

    clientRequestTimelineItem.created_at = new Date(response.created_at);
    clientRequestTimelineItem.created_at_date = new Date(response.created_at);
    clientRequestTimelineItem.created_at_date.setHours(0, 0, 0, 0);
    clientRequestTimelineItem.updated_at = new Date(response.updated_at);

    clientRequestTimelineItem.author_user = clientRequestTimelineItem.author_user ? User.createFromResponse(response.author_user) : null;
    clientRequestTimelineItem.email_message = clientRequestTimelineItem.email_message ? EmailMessage.createFromResponse(response.email_message) : null;

    return clientRequestTimelineItem;
  }
}
