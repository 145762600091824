import {Model} from '../../abstract/model';
import {User} from '../../user/user';
import {TravelResourceType} from '../travel-resource-type/travel-resource-type';
import {LocationCity} from '../location/location-city';

export class TravelResource extends Model {
  id: number;
  name: string;
  status: string;
  internal_description: string;
  operating_schedule: any; // @todo: To be defined, irrelevant now
  luxury_classification: number;
  rating: number;
  rated_at: Date;
  available_from: Date;
  available_until: Date;
  location_street: string;
  location_number: string;
  location_zip: string;
  location_locality: string;
  location_latitude: number;
  location_longitude: number;
  location_gps_name: string;
  location_everywhere: boolean;
  contact_primary: string;
  contact_alternative: string;
  contact_email: string;
  website: string;
  near_notification_options: any; // @todo: To be defined, irrelevant now
  itinerary: any; // @todo: To be defined, irrelevant now
  itinerary_best_time: any; // @todo: To be defined, irrelevant now
  itinerary_duration: any; // @todo: To be defined, irrelevant now

  travel_resource_type: TravelResourceType;
  location_city: LocationCity;

  /**
   * @param {TravelResourceResponse} response
   * @returns {User}
   */
  public static createFromResponse(response: TravelResourceResponse): TravelResource {
    const travelResource = new TravelResource(response);

    travelResource.rated_at = new Date(response.rated_at);
    travelResource.available_from = new Date(response.available_from);
    travelResource.available_until = new Date(response.available_until);

    travelResource.travel_resource_type = TravelResourceType.createFromResponse(response.travel_resource_type);
    travelResource.location_city = response.location_city ? LocationCity.createFromResponse(response.location_city) : null;

    return travelResource;
  }
}
