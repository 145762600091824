import {Model} from '../../abstract/model';
import {CieloOrderBody} from './cielo-order-body';
import {CieloOrderNotification} from './cielo-order-notification';

export class CieloOrder extends Model {

  get paymentStatusName() {
    return CieloOrder.STATUSES[this.payment_status];
  }

  get amount(): number {
    return this.notification ? parseInt(this.notification.amount) / 100 : null;
  }

  static STATUSES = {
    1: 'Pending',
    2: 'Paid',
    3: 'Denied',
    4: 'Expired',
    5: 'Canceled',
    6: 'NotFinished',
    7: 'Authorized',
    8: 'Chargeback',
  };
  order_id: string;
  payment_status: number;
  body: CieloOrderBody;
  notification: CieloOrderNotification;
}
