import {Model} from '../../abstract/model';
import {ClientGroup} from './client-group';
import {LocationCity} from '../location/location-city';
import {LocationCountry} from '../location/location-country';
import {Origin} from '../origin/origin';

export class Client extends Model {

  static languages = {
    pt_BR: 'Brazilian Portuguese',
    en: 'English',
    es: 'Spanish',
  };
  id: number;
  person_id: string;
  fiscal_id: string;
  social_facebook: string;
  social_instagram: string;
  social_twitter: string;
  language: string;
  observations: string;
  name: string;
  surname: string;
  gender: 'm'|'f';
  passport: string;
  location_street: string;
  location_number: string;
  location_zip: string;
  location_locality: string;
  contact_home: string;
  contact_work: string;
  contact_mobile: string;
  contact_email: string;
  contact_newsletter: boolean;

  dob: Date;
  passport_issue: Date;
  passport_expiry: Date;
  created_at: Date;
  updated_at: Date;

  city: LocationCity;
  pob_city: LocationCity;
  nationality: LocationCountry;
  origin: Origin;
  client_group: ClientGroup;

  model_class_name: string;

  /**
   * @param response
   * @returns {Model}
   */
  public static createFromResponse(response: ClientResponse): Client {
    const client =  new this(response);

    client.dob = new Date(response.dob);
    client.passport_issue = new Date(response.passport_issue);
    client.passport_expiry = new Date(response.passport_expiry);
    client.created_at = client.created_at ? new Date(response.created_at) : null;
    client.updated_at = client.updated_at ? new Date(response.updated_at) : null;

    client.city = client.city ? LocationCity.createFromResponse(response.city) : null;
    client.pob_city = client.pob_city ? LocationCity.createFromResponse(response.pob_city) : null;
    client.nationality = client.nationality ? LocationCountry.createFromResponse(response.nationality) : null;
    client.origin = client.origin ? Origin.createFromResponse(response.origin) : null;
    client.client_group = client.client_group ? ClientGroup.createFromResponse(response.client_group) : null;

    return client;
  }

  /**
   * @returns {string}
   */
  public toString(): string {
    const surname = this.surname || '';
    return `${this.name} ${surname}`;
  }

  /**
   * Get the label for client's language
   * @returns {string | undefined}
   */
  public getLanguageLabel(): string|undefined {
    if (this.language) {
      return Client.languages[this.language];
    }
  }
}
