
import {catchError, switchMap} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import {TdDialogService} from '@covalent/core';
import 'rxjs-compat/add/operator/catch';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  static repeatableStatusCodes = [
    0,
    500
  ];

  private _dialogService: TdDialogService;

  constructor(
    private injector: Injector
  ) {
    this._dialogService = this.injector.get(TdDialogService);
  }

  /**
   * Intercept requests adding the AuthToken if available
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.repeatFailedRequests(request, next);
  }

  /**
   * Handle unexpected http error
   * @param request
   * @param next
   */
  private repeatFailedRequests(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        if (ErrorInterceptor.repeatableStatusCodes.indexOf(error.status) === -1) {
          return observableThrowError(error);
        }

        return this.confirmRetry(error).pipe(switchMap((retry: boolean) => {
          if (retry) {
            return this.repeatFailedRequests(request, next);
          } else {
            return observableThrowError(error);
          }
        }));
      }
    }));
  }

  /**
   * Show a confirmation dialog to user decide to retry or not the request.
   *
   * @param {HttpErrorResponse} error
   * @returns {Observable<any>}
   */
  private confirmRetry(error: HttpErrorResponse) {
    let message, title;

    switch (error.status) {
      case 0:
        title = 'Our servers are unreachable';
        message = 'Please check your internet connection or contact our support team.';
        break;
      case 500:
        title = 'Internal server error';
        message = `Please contact our support team and inform that you got an error ${error.status}.`;
        break;
      default:
        title = 'Our bad.';
        message = `Please contact our support team and inform that you got an error ${error.status}.`;
    }

    return this._dialogService.openConfirm({
      title,
      message,
      cancelButton: 'Cancel',
      acceptButton: 'Retry',
    }).afterClosed();
  }
}
